/* button module: core */
// TODO: make more logical separation between core and theme styles

// button,
// input[type="button"],
// input[type="submit"] {
//   background-color: transparent;
// }

.btn,
.button-icon {
  background-color: transparent;
  width: max-content;
  display: flex;
  text-transform: uppercase;
  position: relative;
  overflow: hidden; // clip box for sliding background
  padding: 6*$pxToRem 25*$pxToRem!important;
  min-height: 36*$pxToRem;
  //min-width: 160px;
  align-items: center;
  justify-content: center;
  z-index: 0;
  font-family: $body-font;
  color: $color-black!important;
  font-weight: 400;
  border-radius: 50px;
  border: 1px solid $color-black;
  transition: all $transition-default;
  &::before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all $transition-default;
    transform: translateY(-100%);
  }
  &::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all $transition-default;
    transform: translateY(100%);
  }

  &:hover {
    color: $color-white!important;
    border-color: $color-black;
    &::before {
      background-color: $color-black;
      transform: translateY(-50%);
    }
    &::after {
      background-color: $color-black;
      transform: translateY(50%);
    }
  }
}

.button {
  // Plus sign preceding button text
  //&::before {
  //  content: "+";
  //  padding-right: 4*$pxToRem;
  //}
}

.padding-section-content .button:last-child {
  @include media-mobile-only {
    margin-bottom: $padding-mobile;
  }
}

.button-icon {
  svg {
    display: block;
    width: 16*$pxToRem;
    height: 16*$pxToRem;
    margin-right: 10*$pxToRem;
  }
}

.inverted a.btn,
.inverted a.button-icon,
a.btn.inverted,
a.button-icon.inverted {
  background-color: transparent;
  color: $color-black!important;
  border-color: $color-black;
  &::before {
    //background-color: $color-accent;
  }
  &::after {
    //background-color: $color-accent;
  }
  &:hover {
    color: $color-white!important;
    border-color: $color-accent;
    &::before {
      background-color: $color-accent;
    }
    &::after {
      background-color: $color-accent;
    }
  }
}

a.svg, .svg {
  position: relative;
  &.inverted {
    svg {
      stroke: $color-white;
    }
  }
  svg {
    position: absolute;
    top: -1.5rem;
    left: 50%;
    right: 0;
    bottom: 0;
    pointer-events: none;
    fill: none;
    stroke: $color-gray-11;
    stroke-width: 0.5px;
    margin: 0 auto;
    transform: translateX(-50%);
    path {
      stroke-dasharray: 1;
      stroke-dashoffset: 1;
      transition: stroke-dashoffset 0.4s cubic-bezier(0.7, 0, 0.3, 1);

    }
  }
  &:hover {
    svg {
      path {
        stroke-dashoffset: 0;
        transition-timing-function: cubic-bezier(0.8, 1, 0.7, 1);
        transition-duration: 0.4s;
      }
    }
  }
}