/* menu module: theme */

// Navigation Menu and Company Info modal that slides into view
#menu {
  position: fixed;
  display: grid;
  grid-template-columns: 7.5fr 4.5fr;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%); // menu is translated off screen by default
  transition: transform $transition-aos-transform;
  overflow: auto;

  @include media-below-lg-monitor {
    grid-template-columns: 8fr 4fr;
  }

  @include media-below(1200) {
    grid-template-columns: 1fr;

    & > nav:last-child {
      padding-bottom: 200*$pxToRem;
    }
  }

  & > nav:first-child {
    &.menu__main {
      background: $color-gray-1;
      height: 100%;
      max-height: 100%;
      opacity: 0;
      transition: opacity 0.2s ease;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      @include media-below-desktop {
        justify-content: flex-start;
        padding-bottom: 20px!important;
      }
      @include media-below(1200) {
        padding-bottom: 50px;
      }

      &--align {
        z-index: 10;
      }
      .menu__main--logo {
        position: absolute;
        top: 4%;
        left: 8%;
        @include media-mobile-only {
          position: relative;
          top: 0%!important;
          left: 3%!important;
        }
        @include media-below(1200) {
          position: relative;
          top: 0%;
          left: 4%;
        }
        svg {
          width: 90px;
          height: 70px;
        }
      }
    }
    //background: $color-gray-1 url(#{$imagesPath}bg-tile-nav.jpg) center center / cover no-repeat;
    //height: 100%;
    //max-height: 100%;
    //opacity: 0;
    //transition: opacity 0.2s ease;
    //position: relative;
    //display: flex;
    //flex-direction: column;
    //align-items: center;

    a {
      @include config-type-heading;
      display: block;
      color: $color-white;
      padding: 10*$pxToRem;

      @include media-above(1200) {
        font-size: 40*$pxToRem;
      }

      @include media-below-lg-monitor {
        padding: 0 10*$pxToRem 10*$pxToRem;
        // font-size: 40*$pxToRem;
        line-height: 1.25;
      }

      @include media-below-desktop {
        font-size: 34*$pxToRem;
      }

      &:focus,
      &:hover {
        color: $color-accent;
      }

      &:focus + ul {
        opacity: 1;
        z-index: 9;
      }
    }

    @include media-desktop-only {
      .menu__home-link {
        display: none;
      }

      // First-level <ul>
      & > ul {
        margin: 3rem 27vw 3rem 5vw;
        max-width: max-content;
        position: relative;
        z-index: 10;

        // hide mobile subnav expand/collapse button
        button {
          display: none;
        }

        // Nested <ul>
        ul {
          position: absolute;
          left: calc(100% - #{14*$pxToRem});
          margin-top: -84*$pxToRem;
          width: 30vw;
          max-width: 600*$pxToRem;
          box-sizing: border-box;
          padding-left: 5vw;
          padding-top: 25*$pxToRem;
          opacity: 0;
          z-index: -1;
          transition: opacity $transition-default;

          &:focus-within {
            opacity: 1;
            z-index: 9;
          }

          // Second-level <a>
          a {
            @include config-type-body;
            // padding: 11*$pxToRem 16*$pxToRem;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: $color-white;
            font-size: 16*$pxToRem;

            svg {
              display: block;
              width: 42*$pxToRem;
              height: 29*$pxToRem;
              fill: $color-text-inverted;
              opacity: 0;
              transform: translateX(5*$pxToRem);
              transition: transform $transition-default,
                          opacity $transition-default;
            }

            &:hover,
            &:focus {
              color: $color-accent;
              svg {
                transform: translateX(20*$pxToRem);
                opacity: 1;
              }
            }
          }
        }

        // First-level <li>
        & > li {
          padding-right: 30*$pxToRem;
          
          &:hover,
          &:focus-within {
            // First-level <a>
            & > a {
              color: $color-accent;
            }
    
            &::before {
              @include util-pseudo-layer;
              left: 100%;
            }
    
            // Nested <ul>
            ul {
              opacity: 1;
              z-index: 9;
            }
          }
        }
      }
    }

    // Laptop size
    @include media-below-lg-monitor {
      & > ul > ul {
        padding-left: 1vw;

        a {
          line-height: 1.25;
        }
      }
    }
  }

  // Mobile
  @include media-below-desktop {
    & > nav:first-child {
      transition: none;
      align-items: flex-start;
      padding-top: 30px;
      padding-bottom: 50px;
      background-size: auto;
      background-position: 0 0;
      background-repeat: repeat-y;

      a {
        color: $color-white;
      }

      // First-level <ul>
      & > ul {
        width: 100%;
        box-sizing: border-box;
        padding: $padding-mobile;
        z-index: 99;

        // First-level <li>
        & > li {
          position: relative;

          a {
            @include config-type-subheading;
            width: calc(100% - 50px);
            text-transform: none;
          }

          .menu__sub-menu-wrapper {
            overflow: hidden;
            height: 0;
            transition: height $transition-default;
          }

          // Nested <ul>
          ul {
            margin: 0;
            padding: 0 0 $padding-mobile $padding-mobile;
            opacity: 0;
            transition: opacity $transition-default;

            // Second-level <a>
            a {
              @include config-type-body;
              color: $color-white;
              font-size: 16*$pxToRem!important;
              text-transform: uppercase;

              svg {
                display: none;
              }
            }
          }

          // mobile expand/collapse sub-nav trigger
          button {
            background: transparent;
            width: 40px;
            height: 40px;
            position: absolute;
            right: -8px;
            top: 3px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before,
            &:after {
              display: block;
              content: '';
              width: 14px;
              height: 2px;
              background: $color-white;
              position: absolute;
              opacity: 0.5;
              transition: transform $transition-default,
                          opacity $transition-default;
            }

            &:after {
              transform: rotate(90deg);
            }
          }

          &.menu__sub-menu-expanded {
            & > a {
              color: $color-accent;
            }

            ul {
              opacity: 1;
            }

            button {
              &:before {
                opacity: 1;
              }

              &:after {
                transform: rotate(0deg);
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }

  aside {
    @include util-align-children-center;
    opacity: 0;
    transition: opacity 0.8s ease 0.1s;
    position: relative;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 50px;
    @include media-below(1200) {
      margin-top: 50px;
    }

    .company-info {
      transition: opacity 0.8s ease 0.4s;
      opacity: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .logo {
        margin: auto;
        img {
          max-width: 170px;
          margin: 0 auto;
        }
        svg {
          margin: auto;
        }
        .phone {
          text-align: center;
          margin: 2rem 0;
          a, span {
            color: $color-black;
            font-size: 22*$pxToRem;
            font-weight: 700;
            display: inline-block;
            transition: all $transition-default;
            &:hover {
              color: $color-accent;
            }
            @include media-above-laptop {
              font-size: 27*$pxToRem;
            }
          }
          span {
            margin: 0 0.2rem;
          }
        }
      }
      a {
        //color: $color-black;
        &:hover {
          color: $color-accent;
          &.btn {
            color: $color-white;
          }
        }
        &.btn {
          @include media-below(1200) {
            margin-bottom: 3rem;
          }
        }
      }
      address {
        margin-top: 10px;
        text-transform: capitalize;
        p {
          color: $color-white;
          border: 0;
        }
        a {
          color: $color-white;
          &:hover {
            color: $bg-blue;
          }
        }
      }
    }

    .social {
      transition: opacity 0.8s ease 0.6s;
      opacity: 0;
      display: flex;
      justify-content: center;
      ul {
        a {
          svg {
            fill: $color-black;
          }
          &:hover {
            svg {
              fill: $color-accent;
            }
          }
        }
      }
    }

    //@include media-below(1200) {
    //  padding: 100*$pxToRem 30*$pxToRem;
    //  z-index: 99;
    //
    //  .social {
    //    position: static;
    //    margin-top: 30*$pxToRem;
    //  }
    //}

    //@include media-below-desktop {
    //  transition: none;
    //  padding: 50*$pxToRem 30*$pxToRem;
    //
    //  .company-info {
    //    transition: none;
    //    address {
    //      margin-top: 20px;
    //    }
    //  }
    //
    //  .social {
    //    ul {
    //      justify-content: flex-start;
    //    }
    //    position: static;
    //    margin-top: 30*$pxToRem;
    //  }
    //}
  }
}

// Hamburger / Close button
#menu-trigger {
  @include util-align-children-center;
  padding: 3*$pxToRem 4*$pxToRem;
  position: fixed;
  top: 28*$pxToRem;
  right: 2vw;
  background: transparent;
  z-index: 999;
  transition: background-color 0.2s ease;
  outline-color: $color-text-inverted !important;

  svg {
    width: 60*$pxToRem;
    height: 38*$pxToRem;
    transition: all 0.2s ease;
    fill: $color-white;
    transform: rotate(0deg);
    margin-right: -2px;
    margin-bottom: -2px;
    circle {
      fill: $color-black;
      transition: all $transition-default;
    }
    rect {
      transition: transform 0.3s ease;
      transform-origin: center;
    }
  }

  @include media-mobile-only {
    right: 6px;
    top: 0;
    height: 60px;

    svg {
      width: 40*$pxToRem;
      height: 32*$pxToRem;
      fill: $color-black;
      circle {
        fill: $color-black;
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      svg {
        fill: $color-accent;
        circle {
          fill: $color-accent;
          &:nth-child(1) {
            cy: -2px;
            cx: -2px;
          }
          &:nth-child(2) {
            cy: -2px;
            cx: 24px;
          }
          &:nth-child(3) {
            cy: 24px;
            cx: -2px;
          }
          &:nth-child(4) {
            cy: 24px;
            cx: 24px;
          }
        }
      }
    }

    &:active {
      svg {
        fill: $color-text-inverted;
        transition-duration: 0s;
      }
    }
  }
}

// Inverted Menu Trigger State (when not over banner)

@include media-above-mobile {
  .js-menu-trigger-is-inverted {
    #menu-trigger {
      background: transparent;
    }
  }
}

.js-menu-trigger-is-inverted,
.js-menu-is-active {
  .header__content--info {
    background-color: $color-white;
    a {
      color: $color-black;
    }
    address {
      border-color: $color-black;
    }
  }
  #menu-trigger {
    outline-color: $color-text !important;

    svg {
      fill: $color-black;
      circle {
        fill: $color-black;
      }
    }
    @media (hover: hover) {
      &:hover {
        svg {
          fill: $color-accent;
          circle {
            fill: $color-accent;
          }
        }
      }
    
      &:active {
        svg {
          fill: $color-text;
        }
      }
    }
  }
}

// Active Menu State
// menu will slide into view when document root has active class
// TODO: reconsider js prefix (in keeping with goal to always have parity between class/id prefix and css module name
.js-menu-is-active {
  #menu {
    transform: translateX(0);

    & > nav:first-child {
      opacity: 1;
      transition-duration: 1s;
    }

    aside {
      opacity: 1;

      .company-info,
      .social {
        opacity: 1;
      }
    }
  }

  #menu-trigger {
    background: transparent;

    svg {
      transform: rotate(45deg);
      circle {
        fill: $color-black;
      }
      rect {
        display: none;
        &:first-child {
          transform: rotate(45deg) translateY(10*$pxToRem);
          display: block;
        }

        &:last-child {
          transform: rotate(-45deg) translateY(-10*$pxToRem);
          display: block;
        }
      }
    }
    @media (hover: hover) {
      &:hover {
        svg {
          fill: $color-accent;
          circle {
            fill: $color-accent;
          }
        }
      }

      &:active {
        svg {
          fill: $color-text;
        }
      }
    }

    @include media-below(1200) {
      //background: #2f2f2f;

      svg {
        fill: $color-text-inverted;
        circle {
          fill: $color-white;
        }
      }
    }
  }
}

// Prevents scrolling rest of page while menu modal is active
.js-viewport-frozen {
  overflow: hidden;

  @include media-mobile-only {
    .header,
    main,
    .footer,
    .menu-mobile-cta {
      visibility: hidden;
    }
  }
}

.menu-mobile-cta {
  display: none;

  @include media-mobile-only {
    @include config-fixed-menu($color-accent);
    display: block;
    bottom: 0;
    z-index: 9999;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      padding: 0;

      li {
        flex: 1;

        &:not(:last-child) {
          border-right: 1*$pxToRem solid $color-white;
        }
      }

      a {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        //height: 60px;
        color: $color-white;
        padding: 0.5rem;
        &:hover {
          background-color: $color-white;
          color: $color-accent;
        }
      }
    }
  }
}

//.menu__procs {
//  //margin-top: 1.5rem;
//  padding: 1.5rem 1rem;
//  text-align: center;
//  @include media-above-laptop {
//    padding: 3.5rem 1rem 3.5rem;
//  }
//  ul {
//    list-style: none;
//    margin: 0;
//    padding: 0;
//    li {
//      list-style: none;
//      display: block;
//      margin: 2rem 2rem;
//      @include media-above-laptop {
//        display: inline-block;
//        margin: 0 2rem;
//      }
//      a {
//        font-weight: 400;
//        text-transform: uppercase;
//      }
//    }
//  }
//  &--vertical {
//    padding: 0;
//    ul {
//      li {
//        display: block;
//        margin: 2rem 2rem;
//      }
//    }
//  }
//}

.menu__slider {
  position: absolute;
  left: 4rem;
  bottom: 4rem;
  z-index: 10;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: inline-block;
      margin-right: 2rem;
      a {
        color: $color-white;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}

.menu__list {
  &--vertical {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: block;
      a {
        color: $color-black;
        font-weight: 600;
        text-transform: uppercase;
        width: auto;
        display: inline-block;
        line-height: 2.5;
        &:before {
          content: '';
          background-color: $yellow;
          border-radius: 50px;
          width: 8px;
          height: 8px;
          display: block;
          margin-bottom: -18px;
          margin-left: -12px;
          padding-right: 0;
          transition: all $transition-default;
        }
        &:hover {
          &:before {
            width: 100%;
            padding-right: 20px;
          }
        }
      }
    }
  }
}
.line {
  a {
    color: $color-black;
    font-weight: 600;
    text-transform: uppercase;
    width: auto;
    display: inline-block;
    line-height: 2.5;
    @include media-mobile-only {
      margin-left: 1rem;
    }
    &:before {
      content: '';
      background-color: $yellow;
      border-radius: 50px;
      width: 8px;
      height: 8px;
      display: block;
      margin-bottom: -18px;
      margin-left: -12px;
      padding-right: 0;
      transition: all $transition-default;
    }
    &:hover {
      &:before {
        width: 100%;
        padding-right: 20px;
      }
    }
  }
}

a {
  &.line {
    color: $color-black;
    font-weight: 600;
    text-transform: uppercase;
    width: auto;
    display: inline-block;
    line-height: 2.5;
    @include media-mobile-only {
      margin-left: 1rem;
    }
    &.inverted {
      color: $color-white;
    }
    &:before {
      content: '';
      background-color: $yellow;
      border-radius: 50px;
      width: 8px;
      height: 8px;
      display: block;
      margin-bottom: -18px;
      margin-left: -12px;
      padding-right: 0;
      transition: all $transition-default;
    }
    &:hover {
      &:before {
        width: 100%;
        padding-right: 20px;
      }
    }
  }
}