/* bg module: theme */

.relative {
  position: relative;
}
.bg-gray {
  background-color: $bg-gray;
}
.bg-white {
  background-color: $color-white;
}

.box {
  &-align {
    &-vertically {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
    &-height {
      height: auto;
    }
  }
  &-bg {
    background-color: $bg-blue;
    &-padding {
      padding: 8rem;
    }
  }
  &-quote {
    width: 120px;
    height: 120px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-tablet-only {
      width: 80px;
      height: 80px;
      top: -3rem;
      svg {
        width: 2.5rem;
      }
    }
  }
}

.border {
  &-tl {
    box-shadow: -60px -60px 0 0 $bg-blue;
  }
  &-tr {
    box-shadow: 60px -60px 0 0 $bg-blue;
  }
  &-bl {
    box-shadow: -60px 60px 0 0 $bg-blue;
  }
  &-br {
    box-shadow: 60px 60px 0 0 $bg-blue;
  }
}