.gallery {
	.c-title-sub {
		font-size: 20*$pxToRem;
	  }
	  
	  
	&-titles h2 {
		margin: 1rem auto 3rem;

    span {
      @include config-type-subheading;
      display: block;
    }

	}

  .gallery-links {
    display: flex;
    justify-content: space-between;
    margin: 30px auto 60px;

    @include media-mobile-only {
      flex-direction: column;
      align-items: center;
    }

    a:first-child {
      margin-bottom: 20px;
    }
  }

	  .patient-navigation {
      display: flex;
      margin: 0 auto 30px;
      justify-content: space-around;
      align-items: center;

      .nav-next {
        min-width: 120px;
      }

	  }
}


.case-box__image-wrapper {
  overflow: hidden;
  border-radius: 6px;
}

.case-box__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.patient-single-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;

    span {
    margin: 0.5rem auto;
    display: block;
    }

    .img-wrap {
      overflow: hidden;
      border-radius: 6px;

    img {
      transform: scale(1.025);
    }
  }
}

.archive .menu-svg-links {
	margin: 0;
}

.section__gallery--list>div>div {
	margin-bottom: 5rem;
}

.case-box__images {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
}

#gallery {

  .button {

    a {
      color: #000;
      display: inline-block;
      font-weight: 600;
      line-height: 2.5;
      text-transform: uppercase;
      width: auto;
  
      &:before {
        background-color: #e5bb40;
        border-radius: 50px;
        content: "";
        display: block;
        height: 8px;
        margin-bottom: -18px;
        margin-left: -12px;
        padding-right: 0;
        transition: all .3s ease-in;
        width: 8px;
      }
  
      &:hover:before {
        padding-right: 20px;
        width: 100%;
      }
    }
  
  }
  
}

.patient-navigation,
.gallery-links {
  margin-bottom: 2rem;
}

@include media-mobile-only {

  .nav-label,
  .gallery-parent-link,
  .gallery-link {
    margin-bottom: 1rem;
  }

  .box-bg-padding {
    padding: 8rem 4rem;
  }
}