/* logo module: theme */

.logo {
  &,
  img,
  svg {
    display: block;
  }

  // hack to improve svg antialiasing in Chrome
  svg {
    transform: scale(0.999);
  }
}
